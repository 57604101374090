<template>
  <div class="content-sidebar-outro">
    <CScrollbar class="h-100">
      <div class="header d-flex align-items-center justify-content-between">
        <span>Outro</span>
      </div>

      <ValidationObserver ref="observer">
        <ValidationProvider rules="required" v-slot="{ errors }">
          <div class="body">
            <div class="body-section">
              <h6 class="mb-3">Max results</h6>

              <input
                class="form-control mb-0"
                type="text"
                id="outro-max-results"
                name="Max Results"
                aria-describedby="outro-max-results-feedback"
                placeholder="Max results"
                v-model="outro_max_results"
              />
              <small
                class="feedback text-warning"
                id="outro-max-results-feedback"
                >{{ errors[0] }}</small
              >
            </div>
          </div>
        </ValidationProvider>
      </ValidationObserver>
    </CScrollbar>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { createHelpers } from "vuex-map-fields";

const { mapFields } = createHelpers({
  getterType: "quiz/getField",
  mutationType: "quiz/updateField",
});

export default {
  name: "CSidebarOutro",

  data() {
    return {};
  },

  components: {
    ValidationObserver,
    ValidationProvider,
  },

  computed: {
    ...mapFields(["currentQuiz.outro_max_results"]),
  },

  methods: {},
};
</script>

<style lang="scss" scoped>
.content-sidebar-outro {
  width: 100%;
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 16px 1px rgba(51, 51, 51, 0.1);
  padding: 20px 15px;

  .header {
    margin-bottom: 53px;
    padding: 0 5px;

    span {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #333333;
    }
  }

  .body {
    .body-section {
      margin-bottom: 50px;

      .clear-button {
        max-width: none;
        min-width: auto;
        width: auto;
        padding: 4px;
        margin-right: 8px;
        border: 0;
        border-radius: 0;
      }
    }

    .section-image {
      .image {
        background-image: url("../../../../public/img/default-image.png");
        background-size: cover;
        background-position: center;
        height: 150px;
        width: 100%;
      }
    }

    h6 {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #333333;
      margin: 0;
    }

    .question-select {
      background: #ffffff;
      border: 0.5px solid #808080;
      box-sizing: border-box;
      padding: 7px 9px;

      .question-select-toggler {
        img {
          width: 32px;
          height: 32px;
          margin-right: 15px;
        }

        span {
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 20px;
          color: #1a1a1a;
        }

        &::after {
          display: inline-block;
          vertical-align: 0.255em;
          content: "";
          border-top: 0.3em solid;
          border-right: 0.3em solid transparent;
          border-bottom: 0;
          border-left: 0.3em solid transparent;
          margin-left: 12px;
          position: absolute;
          top: 50%;
          right: 9px;
          transform: translateY(-50%);
        }
      }
    }

    .add-button {
      font-size: 24px;
      font-weight: 100;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 4px;
      width: 24px;
      max-width: 24px;
      min-width: auto;
      height: 24px;
      background: #9d9d9c;
      border: 0;
      color: #fff;

      &:active,
      &:focus {
        outline: none;
      }
    }
  }
}
</style>
