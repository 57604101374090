<template>
  <div class="page-design-questions">
    <div class="d-flex align-items-center justify-content-between mb-4">
      <div class="title">
        <h3>Questions</h3>
        <p>Update questions tree</p>
      </div>
    </div>

    <div class="body">
      <div
        v-for="(question, questionIndex) in questions"
        :key="questionIndex"
        class="mb-5"
      >
        <div class="question-container mb-2">
          <img class="mr-3" src="img/questions/radio.png" />
          <strong class="mr-1">{{ question.order }}.</strong>
          <span class="question">
            {{ question.question || "Question " + question.order }}
          </span>
        </div>

        <div
          v-for="(answer, answerIndex) in question.answers"
          :key="answerIndex"
        >
          <div class="answers-container mb-3">
            <strong class="mr-1"
              >{{ String.fromCharCode(96 + answer.order) }}.</strong
            >
            <span class="answer">
              {{ answer.answer || "Answer " + answer.order }}
            </span>
          </div>

          <div class="mb-3">
            <div class="question-select-container mb-3">
              <div class="d-flex align-items-center justify-content-start">
                <CIcon name="cil-arrow-right" class="mr-2" />
                <CDropdown class="question-select" placement="bottom-start">
                  <template #toggler>
                    <div
                      class="question-select-toggler pr-5 d-flex align-items-center justify-content-start"
                    >
                      <span v-if="answer.next_question !== question.id">
                        <img src="img/questions/radio.png" />
                        <strong class="ml-2 mr-2">
                          {{ selectedQuestions[answer.id].order }}.
                        </strong>
                        <small>
                          {{
                            selectedQuestions[answer.id].question ||
                              "Question " + selectedQuestions[answer.id].order
                          }}
                        </small>
                      </span>
                      <span v-else>
                        <img src="img/quiz-outro.png" />
                        <strong class="ml-2 mr-2">
                          Go to results
                        </strong>
                      </span>
                    </div>
                  </template>

                  <CDropdownItem
                    v-for="(questionOption,
                    questionOptionIndex) in filteredQuestions(question)"
                    :key="questionOptionIndex"
                    @click="selectQuestionOption(questionOption, answer)"
                  >
                    <div
                      v-if="questionOption.id !== question.id"
                      class="option-container"
                    >
                      <img src="img/questions/radio.png" />
                      <strong class="ml-2 mr-2"
                        >{{ questionOption.order }}.</strong
                      >
                      <small>
                        {{
                          questionOption.question ||
                            "Question " + questionOption.order
                        }}
                      </small>
                    </div>
                  </CDropdownItem>
                  <CDropdownItem
                    @click="selectQuestionOption(question, answer)"
                  >
                    <img src="img/quiz-outro.png" />
                    <strong class="ml-2 mr-2">
                      Go to results
                    </strong>
                  </CDropdownItem>
                </CDropdown>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createHelpers } from "vuex-map-fields";

const { mapFields } = createHelpers({
  getterType: "quiz/getField",
  mutationType: "quiz/updateField",
});

export default {
  name: "PDesignAnswers",

  data() {
    return {
      selectedQuestions: {},
    };
  },

  components: {},

  computed: {
    ...mapFields(["currentQuiz.questions"]),
  },

  created() {
    this.populateQuestions();
  },

  mounted() {
    this.populateQuestions();
  },

  methods: {
    selectQuestionOption(question, answer) {
      this.selectedQuestions[answer.id] = question;

      answer.next_question = question.id;

      const questions = [...this.questions];
      const answers = [...question.answers];
      question.answers = answers;
      this.questions = questions;
    },

    populateQuestions() {
      for (const question of this.questions) {
        for (const answer of question.answers) {
          const nextQuestion = this.questions.filter(
            (question) =>
              question.id.toString() === answer.next_question.toString()
          );

          if (nextQuestion.length > 0) {
            this.selectedQuestions[answer.id] = nextQuestion[0];
          } else {
            this.selectedQuestions[answer.id] = this.questions[0];
          }
        }
      }
    },

    filteredQuestions(currentQuestion) {
      return this.questions.filter(
        (question) => question.id !== currentQuestion.id
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.page-design-questions {
  padding: 32px 24px;
  padding-bottom: 80px;
  overflow: visible;

  .body {
    .question-container {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;

      img {
        margin-top: -3px;
      }

      strong {
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 25px;
        color: #333333;
      }

      .question {
        font-weight: normal;
        font-size: 18px;
        line-height: 25px;
        align-items: center;
        color: #333333;
      }
    }

    .answers-container {
      padding: 0 65px;

      strong {
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 25px;
        color: #333333;
        text-transform: uppercase;
      }

      .answer {
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 25px;
        align-items: center;
        color: #333333;
      }
    }

    .question-select-container {
      padding: 0 90px;

      .question-select {
        background: #ffffff;
        border: 0.5px solid #808080;
        box-sizing: border-box;

        min-width: 300px;
        max-width: 560px;
        width: 100%;

        ::v-deep {
          .dropdown-menu.show {
            max-width: 350px;
            margin: 1px -1px !important;
            // transform: none !important;
            // top: 41px !important;
            // left: -1px !important;

            .option-container {
              word-break: break-word;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            a {
              width: 100%;

              small {
                word-break: break-word;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-size: 14px;
              }
            }
          }
        }

        .question-select-toggler {
          min-height: 40px;
          padding: 0 9px;
          cursor: pointer;

          // img {
          //   width: 32px;
          //   height: 32px;
          //   margin-right: 15px;
          // }

          span {
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 20px;
            color: #1a1a1a;
            word-break: break-word;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          &::after {
            display: inline-block;
            vertical-align: 0.255em;
            content: "";
            border-top: 0.3em solid;
            border-right: 0.3em solid transparent;
            border-bottom: 0;
            border-left: 0.3em solid transparent;
            margin-left: 12px;
            position: absolute;
            top: 50%;
            right: 9px;
            transform: translateY(-50%);
          }
        }
      }

      span {
        white-space: nowrap;
        font-weight: normal;
        font-size: 18px;
        line-height: 22px;
        align-items: center;
        color: #333333;
      }
    }
  }
}
</style>
