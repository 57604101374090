<template>
  <div class="design-sidebar-menu">
    <ul>
      <li
        @click="navigateDesign('answers')"
        :class="{
          active: designNavigation === 'answers',
        }"
      >
        <CIcon name="cil-speech" class="mr-2" />Answers
      </li>
      <li
        @click="navigateDesign('results')"
        :class="{
          active: designNavigation === 'results',
        }"
      >
        <CIcon name="cil-envelope-closed" class="mr-2" />Results
      </li>
      <li
        @click="navigateDesign('tree')"
        :class="{
          active: designNavigation === 'tree',
        }"
      >
        <CIcon name="cil-sitemap" class="mr-2" />Questions
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "CDesignSidebarMenu",

  data() {
    return {};
  },

  components: {},

  computed: {
    ...mapState({
      designNavigation: (state) => state.quiz.designNavigation,
    }),

    show() {
      return this.$store.state.app.sidebarShow;
    },

    minimize() {
      return this.$store.state.app.sidebarMinimize;
    },
  },

  methods: {
    ...mapActions({
      navigateDesign: "quiz/navigateDesign",
    }),
  },
};
</script>

<style lang="scss" scoped>
.design-sidebar-menu {
  width: 100%;
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 16px 1px rgba(51, 51, 51, 0.1);
  padding: 0;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      min-height: 40px;
      color: #333333;
      transition: border 0.2s;
      border-left: 0px solid #00338d;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 0.8445rem 1rem;
      cursor: pointer;

      rect,
      path {
        fill: #333333;
        transition: fill 0.2s;
      }

      &.active,
      &:hover {
        background-color: #e4eaf3;
        color: #333333;
        border-left: 8px solid #00338d;

        rect,
        path {
          fill: #333333;
        }
      }
    }
  }
}
</style>
