<template>
  <div class="content-sidebar-result" :key="order">
    <CScrollbar class="h-100">
      <div class="header d-flex align-items-center justify-content-between">
        <span>Result</span>
      </div>

      <ValidationObserver ref="observer">
        <ValidationProvider rules="required" v-slot="{ errors }">
          <div class="body">
            <div class="body-section">
              <h6 class="mb-3">Link</h6>

              <input
                class="form-control mb-0"
                type="text"
                id="link"
                name="Link"
                aria-describedby="link-feedback"
                placeholder="Link"
                v-model="link"
              />
              <small class="feedback text-warning" id="link-feedback">{{
                errors[0]
              }}</small>
            </div>
            <small class="feedback text-warning" id="link-feedback">{{
              errors[0]
            }}</small>

            <div class="body-section">
              <div
                class="mb-3 d-flex align-items-center justify-content-between"
              >
                <h6>Image</h6>
                <div class="d-flex align-items-center justify-content-end">
                  <CButton
                    v-if="image"
                    @click="clearImage"
                    color="outline-primary"
                    class="clear-button d-flex align-items-center justify-content-between"
                  >
                    <CIcon class="m-0 p-0" name="cil-trash" />
                  </CButton>
                  <CButton
                    class="m-0 iemq add-button"
                    @click="$refs.imageUploader.click()"
                  >
                    +
                  </CButton>
                </div>
              </div>

              <div class="section-image">
                <div
                  class="image"
                  :style="{
                    backgroundImage: 'url(' + resultImage + ')',
                  }"
                ></div>
              </div>

              <input
                style="display:none;"
                type="file"
                ref="imageUploader"
                name="image"
                accept="image/gif, image/jpeg, image/png"
                @click="$event.target.value = null"
                @change.prevent="setImage"
                @cancel="() => {}"
              />
            </div>
          </div>
        </ValidationProvider>
      </ValidationObserver>
    </CScrollbar>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { createHelpers } from "vuex-map-fields";

const { mapFields } = createHelpers({
  getterType: "quiz/getField",
  mutationType: "quiz/updateField",
});

const apiUrl = process.env.VUE_APP_API_URL || "http://localhost:1337";

export default {
  name: "CSidebarHome",

  data() {
    return {};
  },

  components: {
    ValidationObserver,
    ValidationProvider,
  },

  computed: {
    ...mapFields([
      "currentResult.image",
      "currentResult.link",
      "currentResult.order",
    ]),

    resultImage() {
      const currentImageUrl =
        this.image && this.image[0] ? this.image[0].url : null;

      return currentImageUrl
        ? (!this.hasLocalImage ? apiUrl : "") + currentImageUrl
        : "/img/default-image.png";
    },

    hasLocalImage() {
      return this.image[0].isNew ? true : false;
    },
  },

  methods: {
    setImage() {
      const imageFile = [...this.$refs.imageUploader.files][0];
      const newImageUrl = URL.createObjectURL(imageFile);
      const newImage = [];

      newImage.push({
        url: newImageUrl,
        name: imageFile.name,
        mime: imageFile.type,
        isNew: true,
        files: this.$refs.imageUploader.files,
      });

      this.image = newImage;
    },

    clearImage() {
      this.image = [];
    },
  },
};
</script>

<style lang="scss" scoped>
.content-sidebar-result {
  width: 100%;
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 16px 1px rgba(51, 51, 51, 0.1);
  padding: 20px 15px;

  .header {
    margin-bottom: 53px;
    padding: 0 5px;

    span {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #333333;
    }
  }

  .body {
    .body-section {
      margin-bottom: 50px;

      .clear-button {
        max-width: none;
        min-width: auto;
        width: auto;
        padding: 4px;
        margin-right: 8px;
        border: 0;
        border-radius: 0;
      }
    }

    .section-image {
      .image {
        background-image: url("../../../../public/img/default-image.png");
        background-size: cover;
        background-position: center;
        height: 150px;
        width: 100%;
      }
    }

    h6 {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #333333;
      margin: 0;
    }

    .question-select {
      background: #ffffff;
      border: 0.5px solid #808080;
      box-sizing: border-box;
      padding: 7px 9px;

      .question-select-toggler {
        img {
          width: 32px;
          height: 32px;
          margin-right: 15px;
        }

        span {
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 20px;
          color: #1a1a1a;
        }

        &::after {
          display: inline-block;
          vertical-align: 0.255em;
          content: "";
          border-top: 0.3em solid;
          border-right: 0.3em solid transparent;
          border-bottom: 0;
          border-left: 0.3em solid transparent;
          margin-left: 12px;
          position: absolute;
          top: 50%;
          right: 9px;
          transform: translateY(-50%);
        }
      }
    }

    .add-button {
      font-size: 24px;
      font-weight: 100;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 4px;
      width: 24px;
      max-width: 24px;
      min-width: auto;
      height: 24px;
      background: #9d9d9c;
      border: 0;
      color: #fff;

      &:active,
      &:focus {
        outline: none;
      }
    }
  }
}
</style>
