<template>
  <div class="page-settings-weigths">
    <div class="d-flex align-items-center justify-content-between mb-4">
      <div class="title">
        <h3>Weights</h3>
        <p>Add, edit or remove quiz weights</p>
      </div>

      <CButton color="primary" class="iemq m-0 add-button" @click="addWeight">
        Add Weight <strong class="ml-2">+</strong>
      </CButton>
    </div>

    <div
      sm="12"
      v-for="(weight, index) in weights"
      class="weight mb-2 w-100"
      :key="index"
    >
      <ValidationObserver ref="observer">
        <ValidationProvider rules="required" v-slot="{ errors }">
          <div
            class="color-block"
            :style="{ backgroundColor: weight.color }"
          ></div>

          <input
            class="m-0"
            type="text"
            id="name"
            name="Name"
            aria-describedby="name-feedback"
            placeholder="Name"
            v-model="weights[index].weight"
            @change.prevent="updateWeightName($event, index)"
          />
          <small class="feedback text-warning" id="name-feedback">{{
            errors[0]
          }}</small>
          <CButton
            @click="deleteWeight(index)"
            v-if="weights.length > 1"
            color="outline-primary"
            class="iemq d-flex align-items-center justify-content-between"
          >
            <CIcon class="m-0 p-0" name="cil-trash" />
          </CButton>
        </ValidationProvider>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { mapState, mapActions } from "vuex";
import { uuid } from "vue-uuid";

export default {
  name: "PSettingsWeights",

  data() {
    return {};
  },

  components: {
    ValidationObserver,
    ValidationProvider,
  },

  computed: {
    ...mapState({
      currentQuiz: (state) => state.quiz.currentQuiz,
      crud: (state) => state.quiz.crud,
    }),

    weights: {
      get() {
        return this.currentQuiz.weights;
      },
      async set(value) {
        await this.updateCurrentQuiz(["weights", value]);
      },
    },
  },

  methods: {
    ...mapActions({
      updateCurrentQuiz: "quiz/updateCurrentQuiz",
    }),

    async updateWeightName(event, index) {
      const weights = [...this.weights];

      weights[index].weight = event.target.value;

      this.weights = weights;
    },

    async addWeight() {
      const weights = [...this.weights];

      const weight = {
        id: uuid.v1(),
        isNew: true,
        weight: "New default weight",
        color: this.randomColor(),
      };

      weights.push(weight);

      this.weights = weights;
    },

    async deleteWeight(index) {
      const weights = [...this.weights];
      weights.splice(index, 1);

      this.weights = weights;
    },

    randomColor() {
      let color = "#";

      for (let i = 0; i < 6; i++) {
        const random = Math.random();
        const bit = (random * 16) | 0;

        color += bit.toString(16);
      }

      return color;
    },
  },
};
</script>

<style lang="scss" scoped>
.page-settings-weigths {
  padding: 32px 24px;

  .add-button {
    strong {
      font-size: 30px;
      font-weight: 100;
    }
  }

  .weight {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    max-width: 560px;

    & > span {
      width: 100%;
    }

    input {
      background: #ffffff;
      border: 1px solid #e1e1e1;
      box-sizing: border-box;
      margin-bottom: 8px;
      min-height: 50px;
      padding: 0 25px;
      width: 100%;
      padding-left: 80px;

      &:focus {
        outline: none;
      }
    }

    .iemq {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 20px;
      max-width: none;
      min-width: auto;
      width: auto;
      padding: 8px;
      border: 0;
      border-radius: 0;
    }

    .color-block {
      background-color: blueviolet;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 25px;
      width: 32px;
      height: 32px;
    }

    small {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 75px;
    }
  }
}
</style>
