<template>
  <div class="content-sidebar-content">
    <CScrollbar class="h-100">
      <div class="header d-flex align-items-center justify-content-between">
        <span>Pages</span>
      </div>

      <div class="body mb-4">
        <div
          :class="{
            element: true,
            active: currentContent === 'home',
          }"
          @click="currentContent = 'home'"
        >
          <div class="d-flex align-items-center justify-content-start">
            <div
              class="element-icon d-flex align-items-center justify-content-center mr-2"
            >
              <img src="img/quiz-home.png" />
            </div>

            <span>Intro</span>
          </div>
        </div>

        <div
          :class="{
            element: true,
            active: currentContent === 'outro',
          }"
          @click="currentContent = 'outro'"
        >
          <div class="d-flex align-items-center justify-content-start">
            <div
              class="element-icon d-flex align-items-center justify-content-center mr-2"
            >
              <img src="img/quiz-outro.png" />
            </div>

            <span>Outro</span>
          </div>
        </div>
      </div>

      <div class="header d-flex align-items-center justify-content-between">
        <span>Questions</span>
        <CButton @click="addQuestion" class="m-0 iemq add-button">+</CButton>
      </div>

      <draggable
        v-model="questions"
        class="body mb-4"
        handle=".dragger"
        ghost-class="ghost-answer"
        @end="updateItemOrder"
      >
        <div
          :class="{
            element: true,
            active:
              question.order === currentQuestion.order &&
              currentContent === 'questions',
          }"
          v-for="(question, index) in questions"
          :key="question.id"
          @click="goToQuestion(question)"
        >
          <div>
            <div class="d-flex align-items-center justify-content-start">
              <svg
                class="mr-3 dragger"
                width="8"
                height="12"
                viewBox="0 0 8 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.33366 9.99996C3.33366 10.7333 2.73366 11.3333 2.00033 11.3333C1.26699 11.3333 0.666992 10.7333 0.666992 9.99996C0.666992 9.26663 1.26699 8.66663 2.00033 8.66663C2.73366 8.66663 3.33366 9.26663 3.33366 9.99996ZM2.00033 4.66663C1.26699 4.66663 0.666992 5.26663 0.666992 5.99996C0.666992 6.73329 1.26699 7.33329 2.00033 7.33329C2.73366 7.33329 3.33366 6.73329 3.33366 5.99996C3.33366 5.26663 2.73366 4.66663 2.00033 4.66663ZM2.00033 0.666626C1.26699 0.666626 0.666992 1.26663 0.666992 1.99996C0.666992 2.73329 1.26699 3.33329 2.00033 3.33329C2.73366 3.33329 3.33366 2.73329 3.33366 1.99996C3.33366 1.26663 2.73366 0.666626 2.00033 0.666626ZM6.00033 3.33329C6.73366 3.33329 7.33366 2.73329 7.33366 1.99996C7.33366 1.26663 6.73366 0.666626 6.00033 0.666626C5.26699 0.666626 4.66699 1.26663 4.66699 1.99996C4.66699 2.73329 5.26699 3.33329 6.00033 3.33329ZM6.00033 4.66663C5.26699 4.66663 4.66699 5.26663 4.66699 5.99996C4.66699 6.73329 5.26699 7.33329 6.00033 7.33329C6.73366 7.33329 7.33366 6.73329 7.33366 5.99996C7.33366 5.26663 6.73366 4.66663 6.00033 4.66663ZM6.00033 8.66663C5.26699 8.66663 4.66699 9.26663 4.66699 9.99996C4.66699 10.7333 5.26699 11.3333 6.00033 11.3333C6.73366 11.3333 7.33366 10.7333 7.33366 9.99996C7.33366 9.26663 6.73366 8.66663 6.00033 8.66663Z"
                  fill="#9D9D9C"
                />
              </svg>

              <div
                class="element-icon d-flex align-items-center justify-content-center mr-2"
              >
                <img src="img/questions/radio.png" />
              </div>

              <strong class="mr-1">{{ question.order }}.</strong>
              <span class="element-title">
                {{ question.question || "Question " + question.order }}
              </span>

              <CDropdown
                color="white p-1 px-2"
                placement="bottom-start"
                :caret="false"
              >
                <template #toggler-content>
                  <svg
                    width="4"
                    height="12"
                    viewBox="0 0 4 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2.00033 3.33329C2.73366 3.33329 3.33366 2.73329 3.33366 1.99996C3.33366 1.26663 2.73366 0.666626 2.00033 0.666626C1.26699 0.666626 0.666992 1.26663 0.666992 1.99996C0.666992 2.73329 1.26699 3.33329 2.00033 3.33329ZM2.00033 4.66663C1.26699 4.66663 0.666992 5.26663 0.666992 5.99996C0.666992 6.73329 1.26699 7.33329 2.00033 7.33329C2.73366 7.33329 3.33366 6.73329 3.33366 5.99996C3.33366 5.26663 2.73366 4.66663 2.00033 4.66663ZM2.00033 8.66663C1.26699 8.66663 0.666992 9.26663 0.666992 9.99996C0.666992 10.7333 1.26699 11.3333 2.00033 11.3333C2.73366 11.3333 3.33366 10.7333 3.33366 9.99996C3.33366 9.26663 2.73366 8.66663 2.00033 8.66663Z"
                      fill="#9D9D9C"
                    />
                  </svg>
                </template>
                <CDropdownItem @click.stop="cloneQuestion(question)">
                  <CIcon name="cil-clone" class="mr-2" /> Clone
                </CDropdownItem>
                <CDropdownItem
                  class="text-danger"
                  v-if="questions.length > 1"
                  @click.stop="deleteQuestion(index)"
                >
                  <CIcon name="cil-trash" class="mr-2" /> Remove
                </CDropdownItem>
              </CDropdown>
            </div>
          </div>
        </div>
      </draggable>

      <div class="header d-flex align-items-center justify-content-between">
        <span>Results</span>
        <CButton @click="addResult" class="m-0 iemq add-button">+</CButton>
      </div>

      <div class="body">
        <div
          :class="{
            element: true,
            active:
              result.order === currentResult.order &&
              currentContent === 'results',
          }"
          v-for="(result, index) in results"
          :key="index"
          @click="goToResult(result)"
        >
          <div class="d-flex align-items-center justify-content-start">
            <div
              class="element-icon d-flex align-items-center justify-content-center mr-2"
            >
              <img src="img/quiz-result.png" />
            </div>

            <span class="element-title">
              {{ result.result || "Result " + result.order }}
            </span>

            <CDropdown
              color="white p-1 px-2"
              placement="bottom-start"
              :caret="false"
            >
              <template #toggler-content>
                <svg
                  width="4"
                  height="12"
                  viewBox="0 0 4 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.00033 3.33329C2.73366 3.33329 3.33366 2.73329 3.33366 1.99996C3.33366 1.26663 2.73366 0.666626 2.00033 0.666626C1.26699 0.666626 0.666992 1.26663 0.666992 1.99996C0.666992 2.73329 1.26699 3.33329 2.00033 3.33329ZM2.00033 4.66663C1.26699 4.66663 0.666992 5.26663 0.666992 5.99996C0.666992 6.73329 1.26699 7.33329 2.00033 7.33329C2.73366 7.33329 3.33366 6.73329 3.33366 5.99996C3.33366 5.26663 2.73366 4.66663 2.00033 4.66663ZM2.00033 8.66663C1.26699 8.66663 0.666992 9.26663 0.666992 9.99996C0.666992 10.7333 1.26699 11.3333 2.00033 11.3333C2.73366 11.3333 3.33366 10.7333 3.33366 9.99996C3.33366 9.26663 2.73366 8.66663 2.00033 8.66663Z"
                    fill="#9D9D9C"
                  />
                </svg>
              </template>
              <CDropdownItem @click.stop="cloneResult(result)">
                <CIcon name="cil-clone" class="mr-2" /> Clone
              </CDropdownItem>
              <CDropdownItem
                class="text-danger"
                v-if="results.length > 1"
                @click.stop="deleteResult(index)"
              >
                <CIcon name="cil-trash" class="mr-2" /> Remove
              </CDropdownItem>
            </CDropdown>
          </div>
        </div>
      </div>
    </CScrollbar>
  </div>
</template>

<script>
import { mapState } from "vuex";
import draggable from "vuedraggable";
import { createHelpers } from "vuex-map-fields";
import { uuid } from "vue-uuid";

const { mapFields } = createHelpers({
  getterType: "quiz/getField",
  mutationType: "quiz/updateField",
});

export default {
  name: "CSidebarContent",

  data() {
    return {};
  },

  components: { draggable },

  computed: {
    ...mapState({
      currentQuestion: (state) => state.quiz.currentQuestion,
      currentResult: (state) => state.quiz.currentResult,
      currentContent: (state) => state.quiz.currentContent,
    }),

    ...mapFields([
      "currentQuiz.questions",
      "currentQuiz.results",
      "currentQuestion",
      "currentResult",
      "currentContent",
    ]),
  },

  methods: {
    async updateItemOrder() {
      const questions = [...this.questions];

      questions.map((question, index) => {
        question.order = index + 1;
      });

      this.questions = questions;
    },

    async addQuestion() {
      const questions = [...this.questions];
      const questionId = uuid.v1();
      const answerId = uuid.v1();

      const question = {
        id: questionId,
        isNew: true,
        locale: "en",
        question: "New default question title",
        order: questions.length + 1,
        required: true,
        image: null,
        answers: [
          {
            id: answerId,
            isNew: true,
            answer: "Default answer title",
            locale: "en",
            order: 1,
            next_question: questionId,
            quizanswers: [],
          },
        ],
      };

      questions.push(question);

      this.questions = questions;
    },

    async addResult() {
      const results = [...this.results];

      const result = {
        id: uuid.v1(),
        isNew: true,
        result: "New default result title",
        link: "https://default.link",
        locale: "en",
        image: null,
        order: results.length + 1,
        quizresults: [],
      };

      results.push(result);

      this.results = results;
    },

    async cloneQuestion(question) {
      const questions = [...this.questions];
      const clonedQuestion = { ...question };

      clonedQuestion.order = questions.length + 1;
      clonedQuestion.id = uuid.v1();
      clonedQuestion.isNew = true;

      questions.push(clonedQuestion);

      this.questions = questions;
    },

    async cloneResult(result) {
      const results = [...this.results];
      const clonedResult = { ...result };

      clonedResult.order = results.length + 1;
      clonedResult.id = uuid.v1();
      clonedResult.isNew = true;

      results.push(clonedResult);

      this.results = results;
    },

    async deleteQuestion(index) {
      const questions = [...this.questions];
      questions.splice(index, 1);

      this.questions = questions;
    },

    async deleteResult(index) {
      const results = [...this.results];
      results.splice(index, 1);

      this.results = results;
    },

    async goToQuestion(question) {
      this.currentQuestion = question;
      this.currentContent = "questions";
    },

    async goToResult(result) {
      this.currentResult = result;
      this.currentContent = "results";
    },
  },
};
</script>

<style lang="scss" scoped>
.content-sidebar-content {
  width: 100%;
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 16px 1px rgba(51, 51, 51, 0.1);
  padding: 20px 5px 20px 15px;

  .header {
    margin-bottom: 23px;
    padding: 0 5px;
    padding-right: 15px;

    span {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #333333;
    }
  }

  .body {
    padding-right: 10px;

    .ghost-answer {
      opacity: 0.5;
      background: #fafafa;
    }

    .element {
      padding: 4px 0 4px 4px;
      cursor: pointer;
      margin-bottom: 16px;

      .dragger {
        cursor: move;
      }

      span {
        flex: 1 0 auto;
      }

      .element-icon {
        display: flex;
        height: 32px;
        min-width: 32px;
        background: #ff0054;
      }

      .element-title {
        width: 100%;
        word-break: break-word;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: center;
        text-align: left;
        flex: 1 0 30%;
      }

      &.active,
      &:hover {
        background: #e4eaf3;
      }
    }
  }

  .add-button {
    font-size: 24px;
    font-weight: 100;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 4px;
    width: 24px;
    max-width: 24px;
    min-width: auto;
    height: 24px;
    background: #9d9d9c;
    border: 0;
    color: #fff;

    &:active,
    &:focus {
      outline: none;
    }
  }
}
</style>
