<template>
  <div
    class="page-quiz-settings d-flex align-items-start justify-content-between"
  >
    <SidebarMenu class="menu"></SidebarMenu>
    <transition name="fade" mode="out-in">
      <SettingsQuiz
        class="body"
        v-if="settingsNavigation === 'quiz'"
      ></SettingsQuiz>
      <SettingsWeights
        class="body"
        v-if="settingsNavigation === 'weights'"
      ></SettingsWeights>
    </transition>
  </div>
</template>

<script>
import { mapState } from "vuex";

import SidebarMenu from "./SidebarMenu";
import SettingsQuiz from "./SettingsQuiz.vue";
import SettingsWeights from "./SettingsWeights.vue";

export default {
  name: "PQuizSettings",

  data() {
    return {};
  },

  components: {
    SidebarMenu,
    SettingsQuiz,
    SettingsWeights,
  },

  computed: {
    ...mapState({
      settingsNavigation: (state) => state.quiz.settingsNavigation,
    }),
  },
};
</script>

<style lang="scss" scoped>
.page-quiz-settings {
  overflow: hidden;

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.2s;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }

  .menu {
    height: calc(100vh - 73px);
    min-width: 255px;
    max-width: 255px;
    width: 255px;
    position: fixed;
    top: 73px;
  }

  .body {
    margin-left: 255px;
    width: calc(100% - 255px);
  }
}
</style>
