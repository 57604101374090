<template>
  <div class="page-settings-quiz">
    <div class="d-flex align-items-center justify-content-between">
      <div class="title">
        <h3>Quiz</h3>
        <p class="mb-0">Update quiz information details</p>
      </div>
    </div>

    <div
      sm="12"
      class="d-flex align-items-center justify-content-start flex-wrap mb-5"
    >
      <CButton
        @click="copyFrontUrl"
        v-if="token && token.token && crud !== 'add'"
        color="outline-primary"
        class="iemq copy-url-link mr-2 mt-1"
      >
        Copy link
      </CButton>

      <a
        :href="fullFrontUrl"
        target="_blank"
        class="mt-1"
        v-if="token && token.token && crud !== 'add'"
      >
        {{ fullFrontUrl }}
      </a>
    </div>

    <ValidationObserver ref="observer">
      <CForm
        slot-scope="{ validate }"
        @submit.prevent="validate().then(handleSubmit)"
      >
        <CRow>
          <CCol sm="12">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <label for="name">Name</label>
              <input
                class="form-control mb-4"
                type="text"
                id="name"
                name="Name"
                aria-describedby="name-feedback"
                placeholder="Name"
                v-model="name"
              />
              <small class="feedback text-warning" id="name-feedback">{{
                errors[0]
              }}</small>
            </ValidationProvider>
          </CCol>

          <CCol sm="12">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <label for="color">Color</label>
              <div class="color-picker">
                <input
                  class="form-control mb-4"
                  type="text"
                  id="color"
                  name="Color"
                  aria-describedby="color-feedback"
                  placeholder="Color"
                  v-model="color"
                />
                <small class="feedback text-warning" id="color-feedback">{{
                  errors[0]
                }}</small>
                <div
                  class="color-block"
                  @click="showColorPicker = !showColorPicker"
                  :style="{ backgroundColor: color }"
                ></div>
              </div>
            </ValidationProvider>
            <Chrome
              :value="{ hex: color }"
              @input="updateColor"
              v-if="showColorPicker"
            />
          </CCol>
        </CRow>

        <CRow class="mb-4">
          <CCol>
            <label for="style">Themes</label>

            <div
              class="styles-row d-flex align-items-center justify-content-start"
            >
              <div class="style mr-3">
                <img class="image-style" src="img/style1.png" />
                <div
                  class="d-flex align-items-center justify-content-between p-3"
                >
                  <span>Active theme</span>

                  <CSwitch
                    :checked="theme === 'theme1'"
                    class="ml-3"
                    color="primary"
                    shape="pill"
                    @update:checked="updateTheme('theme1')"
                  />
                </div>
              </div>
              <div class="style">
                <img class="image-style" src="img/style2.png" />
                <div
                  class="d-flex align-items-center justify-content-between p-3"
                >
                  <span>Active theme</span>

                  <CSwitch
                    :checked="theme === 'theme2'"
                    class="ml-3"
                    color="primary"
                    shape="pill"
                    @update:checked="updateTheme('theme2')"
                  />
                </div>
              </div>
            </div>
          </CCol>
        </CRow>

        <div
          class="options-row d-flex align-items-center justify-content-start"
        >
          <CButton
            @click="tryToDeleteQuiz()"
            v-if="crud === 'edit'"
            color="outline-primary"
            class="iemq d-flex align-items-center justify-content-between"
            :disabled="loading"
          >
            Remove quiz <CIcon class="m-0 ml-1" name="cil-trash" />
          </CButton>
        </div>
      </CForm>
    </ValidationObserver>

    <CModal :show.sync="deleteModal">
      <p>
        Delete this quiz permanently?
      </p>

      <template #footer>
        <div class="d-flex align-items-center justify-content-center w-100">
          <CButton
            @click="cancelDeleteConfirmation"
            color="outline-primary"
            class="iemq mr-3"
          >
            No
          </CButton>
          <CButton
            @click="acceptDeleteConfirmation"
            color="primary"
            class="iemq"
          >
            Yes, I'm sure
          </CButton>
        </div>
      </template>
    </CModal>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { mapState, mapActions } from "vuex";
import { Chrome } from "vue-color";
import { debounce } from "debounce";
import axios from "axios";
import { createHelpers } from "vuex-map-fields";

const { mapFields } = createHelpers({
  getterType: "quiz/getField",
  mutationType: "quiz/updateField",
});

const apiUrl = process.env.VUE_APP_API_URL || "http://localhost:1337";
const frontUrl = process.env.VUE_APP_FRONT_URL || "http://localhost:3000";

export default {
  name: "PSettingsQuiz",

  data() {
    return {
      deleteModal: false,
      showColorPicker: false,
    };
  },

  components: {
    ValidationObserver,
    ValidationProvider,
    Chrome,
  },

  computed: {
    ...mapState({
      jwtToken: (state) => state.auth.jwt,
      crud: (state) => state.quiz.crud,
      loading: (state) => state.app.loading,
    }),

    ...mapFields([
      "currentQuiz",
      "currentQuiz.token",
      "currentQuiz.name",
      "currentQuiz.color",
      "currentQuiz.theme",
    ]),

    fullFrontUrl() {
      return `${frontUrl}?quiz=${this.currentQuiz.id}&token=${this.token.token}`;
    },
  },

  methods: {
    ...mapActions({
      startLoading: "app/startLoading",
      stopLoading: "app/stopLoading",
    }),

    // updateColor: debounce(async function(value) {
    //   this.color = value.hex;
    // }, 200),

    async updateColor(value) {
      this.color = value.hex;
    },

    updateTheme(theme) {
      this.theme = theme;
    },

    copyFrontUrl() {
      const clipboardData =
        event.clipboardData ||
        window.clipboardData ||
        event.originalEvent?.clipboardData ||
        navigator.clipboard;

      clipboardData.writeText(this.fullFrontUrl);

      this.$toasted.success("Link copied", {
        icon: "check_circle",
      });
    },

    tryToDeleteQuiz() {
      this.deleteModal = true;
    },

    cancelDeleteConfirmation() {
      this.deleteModal = false;
    },

    async acceptDeleteConfirmation() {
      this.deleteModal = false;

      try {
        await this.startLoading();
        await this.deleteQuiz();
        this.goBack();
      } catch (error) {
        console.log({ error });
      } finally {
        await this.stopLoading();
      }
    },

    async deleteQuiz() {
      await axios.delete(`${apiUrl}/quizzes/${this.quiz.id}`, {
        headers: {
          Authorization: `Bearer ${this.jwtToken}`,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.page-settings-quiz {
  padding: 32px 24px;

  .feedback {
    position: absolute;
    bottom: 0;
  }

  .options-row {
    margin-top: 100px;
    max-width: 560px;
    margin-bottom: 40px;
  }

  .color-picker {
    position: relative;

    .color-block {
      height: 38px;
      width: 38px;
      position: absolute;
      left: 1px;
      top: 1px;
      cursor: pointer;
    }

    input {
      padding-left: 50px;
    }
  }

  .copy-url-link {
    font-size: 14px;
    cursor: pointer;
    padding: 5px 10px;
    max-width: none;
    min-width: auto;
  }

  input {
    max-width: 560px;
  }

  .styles-row {
    .style {
      border: 1px solid #e1e1e1;
      background: #fff;

      .image-style {
        border-bottom: 1px solid #e1e1e1;
        height: 150px;
      }
    }
  }
}
</style>
