<template>
  <div class="settings-sidebar-menu">
    <CScrollbar class="h-100">
      <ul>
        <li
          @click="navigateSettings('quiz')"
          :class="{
            active: settingsNavigation === 'quiz',
          }"
        >
          <CIcon name="cil-notes" class="mr-2" />Quiz
        </li>
        <li
          @click="navigateSettings('weights')"
          :class="{
            active: settingsNavigation === 'weights',
          }"
        >
          <CIcon name="cil-balance-scale" class="mr-2" />Weights
        </li>
      </ul>
    </CScrollbar>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "CSettingsSidebarMenu",

  data() {
    return {};
  },

  components: {},

  computed: {
    ...mapState({
      settingsNavigation: (state) => state.quiz.settingsNavigation,
    }),

    show() {
      return this.$store.state.app.sidebarShow;
    },

    minimize() {
      return this.$store.state.app.sidebarMinimize;
    },
  },

  methods: {
    ...mapActions({
      navigateSettings: "quiz/navigateSettings",
    }),
  },
};
</script>

<style lang="scss" scoped>
.settings-sidebar-menu {
  width: 100%;
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 16px 1px rgba(51, 51, 51, 0.1);
  padding: 0;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      min-height: 40px;
      color: #333333;
      transition: border 0.2s;
      border-left: 0px solid #00338d;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 0.8445rem 1rem;
      cursor: pointer;

      polygon,
      rect,
      path {
        fill: #333333;
        transition: fill 0.2s;
      }

      &.active,
      &:hover {
        background-color: #e4eaf3;
        color: #333333;
        border-left: 8px solid #00338d;

        polygon,
        rect,
        path {
          fill: #333333;
        }
      }
    }
  }
}
</style>
