<template>
  <div
    class="page-quiz-content d-flex align-items-start justify-content-between"
  >
    <SidebarContent class="content"></SidebarContent>

    <transition name="fade" mode="out-in">
      <HomeBody v-if="currentContent === 'home'" class="body"></HomeBody>
      <OutroBody v-if="currentContent === 'outro'" class="body"></OutroBody>
      <QuestionBody
        v-if="currentContent === 'questions'"
        class="body"
      ></QuestionBody>
      <ResultBody v-if="currentContent === 'results'" class="body"></ResultBody>
    </transition>

    <SidebarHome v-if="currentContent === 'home'" class="detail"></SidebarHome>
    <SidebarOutro
      v-if="currentContent === 'outro'"
      class="detail"
    ></SidebarOutro>

    <SidebarQuestion
      v-if="currentContent === 'questions'"
      class="detail"
    ></SidebarQuestion>
    <SidebarResult
      v-if="currentContent === 'results'"
      class="detail"
    ></SidebarResult>
  </div>
</template>

<script>
import { mapState } from "vuex";

import SidebarContent from "./SidebarContent";
import SidebarQuestion from "./SidebarQuestion";
import SidebarHome from "./SidebarHome";
import SidebarOutro from "./SidebarOutro";
import SidebarResult from "./SidebarResult";
import QuestionBody from "./QuestionBody";
import HomeBody from "./HomeBody";
import OutroBody from "./OutroBody";
import ResultBody from "./ResultBody";

export default {
  name: "PQuizContent",

  data() {
    return {};
  },

  components: {
    SidebarContent,
    SidebarQuestion,
    QuestionBody,
    HomeBody,
    OutroBody,
    ResultBody,
    SidebarHome,
    SidebarOutro,
    SidebarResult,
  },

  computed: {
    ...mapState({
      currentContent: (state) => state.quiz.currentContent,
    }),
  },

  methods: {},
};
</script>

<style lang="scss" scoped>
.page-quiz-content {
  overflow: hidden;

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.2s;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }

  .content {
    height: calc(100vh - 73px);
    min-width: 255px;
    max-width: 255px;
    width: 255px;
    position: fixed;
    top: 73px;
  }

  .body {
    width: calc(100% - 510px);
    margin: 0 255px;
  }

  .detail {
    height: calc(100vh - 73px);
    min-width: 255px;
    max-width: 255px;
    width: 255px;
    position: fixed;
    top: 73px;
    right: 0;
  }
}
</style>
