<template>
  <div class="content-home-body">
    <div class="body-container">
      <div class="body-header">
        <span
          class="mb-3 editable"
          data-placeholder="Intro title"
          v-bind:innerText.prop="title"
          contentEditable="true"
          @blur.prevent="updateContent($event, 'title')"
        ></span>

        <small
          class="mb-5 editable"
          data-placeholder="Intro subtitle"
          v-bind:innerText.prop="subtitle"
          contentEditable="true"
          @blur.prevent="updateContent($event, 'subtitle')"
        ></small>

        <div class="cta">
          <span
            class="editable"
            data-placeholder="Intro CTA"
            v-bind:innerText.prop="cta"
            contentEditable="true"
            @blur.prevent="updateContent($event, 'cta')"
          ></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createHelpers } from "vuex-map-fields";
import { debounce } from "debounce";

const { mapFields } = createHelpers({
  getterType: "quiz/getField",
  mutationType: "quiz/updateField",
});

export default {
  name: "CHomeBody",

  data() {
    return {};
  },

  methods: {
    // updateContent: debounce(async function(event, content) {
    //   this[content] = event.target.innerText;
    // }, 200),

    async updateContent(event, content) {
      this[content] = event.target.innerText;
    },
  },

  computed: {
    ...mapFields([
      "currentQuiz.title",
      "currentQuiz.subtitle",
      "currentQuiz.cta",
    ]),
  },
};
</script>

<style lang="scss" scoped>
.content-home-body {
  padding: 40px 25px;

  .editable {
    cursor: text;

    &:empty {
      font-style: italic;
    }

    &:empty:before {
      content: attr(data-placeholder);
    }
  }

  .body-container {
    background: #ffffff;
    width: 100%;
    height: 100%;
    padding: 150px 144px;

    .body-header {
      position: relative;
      margin-bottom: 45px;

      & > span {
        font-weight: 700;
        font-size: 28px;
        line-height: 34px;
        color: #333333;
        display: block;
      }

      & > small {
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;
        color: #9d9d9c;
        display: block;
      }

      .cta {
        background: #f6f6f7;
        border: 1px solid #333333;
        box-sizing: border-box;
        padding: 9px 23px 9px 18px;
        display: inline-block;

        span {
          font-size: 24px;
          color: #333333;
          font-weight: normal;
          font-size: 24px;
          line-height: 29px;
        }
      }
    }
  }
}
</style>
