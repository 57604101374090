<template>
  <div class="page-quiz">
    <transition name="fade" mode="out-in">
      <Settings v-if="navigation === 'settings'"></Settings>
      <Content v-if="navigation === 'content'"></Content>
      <Design v-if="navigation === 'design'"></Design>
    </transition>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import axios from "axios";

import Settings from "./settings/Settings";
import Content from "./content/Content";
import Design from "./design/Design";
import { uuid } from "vue-uuid";

const apiUrl = process.env.VUE_APP_API_URL || "http://localhost:1337";

export default {
  name: "PQuiz",

  data() {
    return {};
  },

  components: {
    Settings,
    Content,
    Design,
  },

  computed: {
    ...mapState({
      jwtToken: (state) => state.auth.jwt,
      navigation: (state) => state.quiz.navigation,
      currentQuiz: (state) => state.quiz.currentQuiz,
    }),
  },

  async created() {
    try {
      await this.startLoading();
      await this.fetchQuiz();
    } catch (error) {
      console.log({ error });
    } finally {
      await this.stopLoading();
    }
  },

  methods: {
    ...mapActions({
      startLoading: "app/startLoading",
      stopLoading: "app/stopLoading",
      navigate: "quiz/navigate",
      setCrud: "quiz/setCrud",
      initializeCurrentQuiz: "quiz/initializeCurrentQuiz",
    }),

    randomColor(offset = 0) {
      let color = "#";

      for (let i = 0; i < 6; i++) {
        const random = Math.random() + offset;
        const bit = (random * 16) | 0;
        color += bit.toString(16);
      }

      return color;
    },

    async fetchQuiz() {
      const quizId = this.$route.params.id;

      if (quizId) {
        await this.setCrud("edit");

        const quizResponse = await axios.get(`${apiUrl}/quizzes/${quizId}`, {
          headers: {
            Authorization: `Bearer ${this.jwtToken}`,
          },
        });

        await this.initializeCurrentQuiz(quizResponse.data);
      } else {
        await this.setCrud("add");

        const questionId = uuid.v1();
        const answerId = uuid.v1();
        const resultId = uuid.v1();
        const weightId = uuid.v1();
        const quizAnswerId = uuid.v1();
        const quizResultId = uuid.v1();

        const quiz = {
          color: this.randomColor(),
          isNew: true,
          locale: "en",
          name: "Default quiz name",
          theme: "theme1",
          title: "Default quiz title",
          outro_title: "Default outro title",
          outro_max_results: 3,
          image: null,
          token: null,
          questions: [
            {
              id: questionId,
              isNew: true,
              locale: "en",
              question: "Default question title",
              order: 1,
              required: true,
              image: null,
              answers: [
                {
                  id: answerId,
                  isNew: true,
                  answer: "Default answer title",
                  locale: "en",
                  order: 1,
                  next_question: questionId,
                  quizanswers: [
                    {
                      id: quizAnswerId,
                      isNew: true,
                      answer: answerId,
                      weight: weightId,
                      value: 50,
                    },
                  ],
                },
              ],
            },
          ],
          weights: [
            {
              id: weightId,
              isNew: true,
              weight: "Default weight",
              color: this.randomColor(),
            },
          ],
          results: [
            {
              id: resultId,
              isNew: true,
              locale: "en",
              result: "Default result title",
              order: 1,
              link: "https://default.link",
              image: null,
              quizresults: [
                {
                  id: quizResultId,
                  isNew: true,
                  result: resultId,
                  weight: weightId,
                  value: 50,
                },
              ],
            },
          ],
        };

        await this.initializeCurrentQuiz(quiz);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.page-quiz {
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.2s;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
}
</style>
