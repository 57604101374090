<template>
  <div class="page-design-results">
    <div class="d-flex align-items-center justify-content-between mb-4">
      <div class="title">
        <h3>Results</h3>
        <p>Update results weights</p>
      </div>
    </div>

    <div class="body">
      <div
        v-for="(result, resultIndex) in results"
        :key="resultIndex"
        class="mb-5"
      >
        <div class="result-container mb-2">
          <img class="mr-3" src="img/quiz-result.png" />
          <strong class="mr-1">{{ resultIndex + 1 }}.</strong>
          <span class="result">
            {{ result.result || "Result " + result.order }}
          </span>
        </div>

        <div class="mb-5">
          <div v-for="(weight, weightIndex) in weights" :key="weightIndex">
            <div class="weight-container mb-3">
              <div class="d-flex align-items-center justify-content-start">
                <div class="range-slider">
                  <input
                    class="range-slider-range"
                    :dataBackground="weight.color"
                    :id="'quizresult-' + weight.id"
                    type="range"
                    :value="getQuizResultValue(result, weight)"
                    @change.prevent="
                      updateQuizResultValue($event, result, weight)
                    "
                    min="0"
                    :max="100"
                  />

                  <input
                    class="range-slider-value"
                    :style="{
                      background: weight.color + '3F',
                      border: '0',
                    }"
                    :value="getQuizResultValue(result, weight)"
                    @input.prevent="
                      updateQuizResultValue($event, result, weight)
                    "
                  />
                </div>

                <span>{{ weight.weight }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createHelpers } from "vuex-map-fields";
import { uuid } from "vue-uuid";

const { mapFields } = createHelpers({
  getterType: "quiz/getField",
  mutationType: "quiz/updateField",
});

export default {
  name: "PDesignResults",

  data() {
    return {};
  },

  components: {},

  computed: {
    ...mapFields([
      "currentQuiz.questions",
      "currentQuiz.results",
      "currentQuiz.weights",
    ]),
  },

  methods: {
    getQuizResultValue(result, weight) {
      const quizResult = result.quizresults.filter(
        (quizResultElement) => quizResultElement.weight === weight.id
      )[0];

      return quizResult ? quizResult.value : 0;
    },

    async updateQuizResultValue(event, result, weight) {
      let value = parseInt(event.target.value);

      if (isNaN(value)) {
        value = 0;
      } else if (value < 0) {
        value = 0;
      } else if (value > 100) {
        value = 100;
      }
      if (!result.quizresults) {
        result.quizresults = [];
      }

      const quizResult = result.quizresults.filter(
        (quizResultElement) => quizResultElement.weight === weight.id
      )[0];

      if (!quizResult) {
        result.quizresults.push({
          value,
          weight: weight.id,
          result: result.id,
          id: uuid.v1(),
          isNew: true,
        });
      } else {
        quizResult.value = value;
      }

      const results = [...this.results];
      this.results = results;
    },
  },
};
</script>

<style lang="scss" scoped>
.page-design-results {
  padding: 32px 24px;

  .body {
    .result-container {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;

      & > img {
        margin-top: -3px;
      }

      strong {
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 25px;
        color: #333333;
      }

      .result {
        font-weight: normal;
        font-size: 18px;
        line-height: 25px;
        align-items: center;
        color: #333333;
      }
    }

    .weight-container {
      padding: 0 50px;

      span {
        white-space: nowrap;
        font-weight: normal;
        font-size: 16px;
        line-height: 22px;
        align-items: center;
        color: #333333;
      }

      .range-slider {
        max-width: 400px;
        width: 100%;
      }

      .range-slider-range {
        -webkit-appearance: none;
        width: calc(100% - 70px);
        height: 10px;
        border-radius: 5px;
        background: #d7dcdf;
        outline: none;
        padding: 0;
        margin: 0;

        &::-webkit-slider-thumb {
          appearance: none;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background: #2c3e50;
          cursor: pointer;
          transition: background 0.15s ease-in-out;

          &:hover {
            background: attr(dataBackground);
          }
        }

        &:active::-webkit-slider-thumb {
          background: attr(dataBackground);
        }

        &::-moz-range-thumb {
          width: 20px;
          height: 20px;
          border: 0;
          border-radius: 50%;
          background: #2c3e50;
          cursor: pointer;
          transition: background 0.15s ease-in-out;

          &:hover {
            background: attr(dataBackground);
          }
        }

        &:active::-moz-range-thumb {
          background: attr(dataBackground);
        }

        &:focus {
          &::-webkit-slider-thumb {
            box-shadow: 0 0 0 3px #fff, 0 0 0 6px #1abc9c;
          }
        }
      }

      .range-slider-value {
        display: inline-block;
        position: relative;
        line-height: 20px;
        text-align: center;
        border-radius: 3px;
        color: #000;
        padding: 3px 6px;
        margin-left: 8px;
        font-weight: bolder;
        width: 45px;

        // &:after {
        //   position: absolute;
        //   top: 8px;
        //   left: -7px;
        //   width: 0;
        //   height: 0;
        //   border-top: 7px solid transparent;
        //   border-right: 7px solid #2c3e50;
        //   border-bottom: 7px solid transparent;
        //   content: "";
        // }
      }

      ::-moz-range-track {
        background: #d7dcdf;
        border: 0;
      }

      input::-moz-focus-inner,
      input::-moz-focus-outer {
        border: 0;
      }
    }
  }
}
</style>
