<template>
  <div class="content-question-body" :key="order">
    <div class="body-container">
      <div class="body-header">
        <strong>{{ order }}.</strong>
        <span
          class="editable"
          data-placeholder="Question title"
          v-bind:innerText.prop="question"
          contentEditable="true"
          @blur.prevent="updateContent($event, 'question')"
        ></span>

        <small
          class=" editable"
          data-placeholder="Question description"
          v-bind:innerText.prop="description"
          contentEditable="true"
          @blur.prevent="updateContent($event, 'description')"
        ></small>
      </div>

      <div class="body-answers">
        <ul>
          <li
            class="d-flex align-items-center justify-content-start"
            v-for="(answer, index) in answers"
            :key="answer.id"
          >
            <svg
              class="mr-3 dragger"
              width="8"
              height="12"
              viewBox="0 0 8 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.33366 9.99996C3.33366 10.7333 2.73366 11.3333 2.00033 11.3333C1.26699 11.3333 0.666992 10.7333 0.666992 9.99996C0.666992 9.26663 1.26699 8.66663 2.00033 8.66663C2.73366 8.66663 3.33366 9.26663 3.33366 9.99996ZM2.00033 4.66663C1.26699 4.66663 0.666992 5.26663 0.666992 5.99996C0.666992 6.73329 1.26699 7.33329 2.00033 7.33329C2.73366 7.33329 3.33366 6.73329 3.33366 5.99996C3.33366 5.26663 2.73366 4.66663 2.00033 4.66663ZM2.00033 0.666626C1.26699 0.666626 0.666992 1.26663 0.666992 1.99996C0.666992 2.73329 1.26699 3.33329 2.00033 3.33329C2.73366 3.33329 3.33366 2.73329 3.33366 1.99996C3.33366 1.26663 2.73366 0.666626 2.00033 0.666626ZM6.00033 3.33329C6.73366 3.33329 7.33366 2.73329 7.33366 1.99996C7.33366 1.26663 6.73366 0.666626 6.00033 0.666626C5.26699 0.666626 4.66699 1.26663 4.66699 1.99996C4.66699 2.73329 5.26699 3.33329 6.00033 3.33329ZM6.00033 4.66663C5.26699 4.66663 4.66699 5.26663 4.66699 5.99996C4.66699 6.73329 5.26699 7.33329 6.00033 7.33329C6.73366 7.33329 7.33366 6.73329 7.33366 5.99996C7.33366 5.26663 6.73366 4.66663 6.00033 4.66663ZM6.00033 8.66663C5.26699 8.66663 4.66699 9.26663 4.66699 9.99996C4.66699 10.7333 5.26699 11.3333 6.00033 11.3333C6.73366 11.3333 7.33366 10.7333 7.33366 9.99996C7.33366 9.26663 6.73366 8.66663 6.00033 8.66663Z"
                fill="#9D9D9C"
              />
            </svg>
            <div
              class="answer-icon d-flex align-items-center justify-content-center"
            >
              <span>{{ String.fromCharCode(96 + answer.order) }}</span>
            </div>

            <span
              class="editable"
              :data-placeholder="'Answer ' + answers[index].order"
              v-bind:innerText.prop="answers[index].answer"
              contentEditable="true"
              @blur.prevent="updateAnswer($event, index)"
            ></span>

            <CButton
              @click="deleteAnswer(index)"
              color="outline-dark"
              class="iemq d-flex align-items-center justify-content-between"
            >
              <CIcon class="m-0 p-0" name="cil-trash" />
            </CButton>
          </li>
        </ul>
      </div>

      <CButton
        class="m-0 p-1 add-button d-flex align-items-center justify-content-center"
        @click="addAnswer"
      >
        <strong class="mr-2">+</strong> Add answer
      </CButton>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { createHelpers } from "vuex-map-fields";
import { debounce } from "debounce";
import { uuid } from "vue-uuid";

const { mapFields } = createHelpers({
  getterType: "quiz/getField",
  mutationType: "quiz/updateField",
});

export default {
  name: "CQuestionBody",

  data() {
    return {};
  },

  components: {},

  computed: {
    ...mapState({
      currentQuiz: (state) => state.quiz.currentQuiz,
      currentQuestion: (state) => state.quiz.currentQuestion,
    }),

    ...mapFields([
      "currentQuiz.questions",
      "currentQuestion.question",
      "currentQuestion.description",
      "currentQuestion.order",
      "currentQuestion.answers",
    ]),
  },

  methods: {
    ...mapActions({
      setCurrentQuiz: "quiz/setCurrentQuiz",
      setCurrentQuestion: "quiz/setCurrentQuestion",
    }),

    // updateContent: debounce(async function(event, content) {
    //   this[content] = event.target.innerText;
    // }, 200),

    // updateAnswer: debounce(async function(event, index) {
    //   const answers = [...this.answers];

    //   answers[index].answer = event.target.innerText;
    // }, 200),

    async updateContent(event, content) {
      this[content] = event.target.innerText;
    },

    async updateAnswer(event, index) {
      const answers = [...this.answers];

      answers[index].answer = event.target.innerText;
    },

    async addAnswer() {
      const answers = [...this.answers];

      const answer = {
        id: uuid.v1(),
        isNew: true,
        locale: "en",
        next_question: this.questions[0].id || 1,
        answer: "New default answer",
        order: this.currentQuestion.answers.length + 1,
        quizanswers: [],
      };

      answers.push(answer);

      this.answers = answers;
    },

    async deleteAnswer(index) {
      const answers = [...this.answers];
      answers.splice(index, 1);

      this.answers = answers;
    },
  },
};
</script>

<style lang="scss" scoped>
.content-question-body {
  padding: 40px 25px;

  .add-button {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #000000;

    strong {
      font-size: 20px;
      font-weight: 100;
      color: #000000;
    }
  }

  .editable {
    cursor: text;

    &:empty {
      font-style: italic;
    }

    &:empty:before {
      content: attr(data-placeholder);
    }
  }

  .body-container {
    background: #ffffff;
    width: 100%;
    height: 100%;
    padding: 150px 144px;

    .body-header {
      position: relative;
      margin-bottom: 45px;

      strong {
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 39px;
        color: #9d9d9c;
        position: absolute;
        left: -35px;
      }

      span {
        font-weight: 500;
        font-size: 28px;
        line-height: 34px;
        color: #333333;
        display: block;
      }

      small {
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;
        color: #9d9d9c;
        display: block;
      }
    }

    .body-answers {
      ul {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
          background: #f6f6f7;
          border: 1px solid #333333;
          box-sizing: border-box;
          padding: 9px;
          margin-bottom: 24px;
          position: relative;
          padding-right: 60px;

          .dragger {
            cursor: move;
          }

          .iemq {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 10px;
            max-width: none;
            min-width: auto;
            width: auto;
            padding: 8px;
            border: 0;
          }

          .answer-icon {
            width: 32px;
            height: 32px;
            background: #e1e1e1;
            border: 0.5px solid #9d9d9c;
            box-sizing: border-box;
            margin-right: 12px;

            span {
              font-size: 24px;
              color: #333333;
              text-transform: uppercase;
              display: block;
            }
          }

          & > span {
            font-weight: normal;
            font-size: 24px;
            line-height: 29px;
            color: #333333;
            display: block;
            width: 100%;
          }
        }
      }
    }
  }
}
</style>
