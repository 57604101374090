<template>
  <div
    class="page-quiz-design d-flex align-items-start justify-content-between"
  >
    <SidebarMenu class="menu"></SidebarMenu>

    <transition name="fade" mode="out-in">
      <DesignAnswers
        class="body"
        v-if="designNavigation === 'answers'"
      ></DesignAnswers>
      <DesignResults
        class="body"
        v-if="designNavigation === 'results'"
      ></DesignResults>
      <DesignTree class="body" v-if="designNavigation === 'tree'"></DesignTree>
    </transition>
  </div>
</template>

<script>
import { mapState } from "vuex";

import SidebarMenu from "./SidebarMenu";
import DesignAnswers from "./DesignAnswers.vue";
import DesignResults from "./DesignResults.vue";
import DesignTree from "./DesignTree.vue";

export default {
  name: "PQuizDesign",

  data() {
    return {};
  },

  components: {
    SidebarMenu,
    DesignAnswers,
    DesignResults,
    DesignTree,
  },

  computed: {
    ...mapState({
      designNavigation: (state) => state.quiz.designNavigation,
    }),
  },

  methods: {},
};
</script>

<style lang="scss" scoped>
.page-quiz-design {
  overflow: visible;

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.2s;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }

  .menu {
    height: calc(100vh - 73px);
    min-width: 255px;
    max-width: 255px;
    width: 255px;
    position: fixed;
    top: 73px;
  }

  .body {
    margin-left: 255px;
    width: 100%;
  }
}
</style>
